import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import CircularProgress from '@mui/material/CircularProgress'
import { Button } from '@mui/material'
import { useQueryGetVehicleById } from '@hooks/ReactQuery/vehicles/useQueryGetVehicleById'
import { CircleLink360, CircleLinkYoutube, LinkWarranty } from '@controls/buttons'
import { ModalSuccess } from '@controls/dialogs/ModalSuccess'
import { MediaSlider, ModalMediaSlider, ModalPanorama } from '@controls/MediaSlider'
import { ModalPlayer } from '@controls/MediaSlider/ModalPlayer'
import { VehicleActualInfo, VehicleLinksToActualInfo } from '@controls/panels'
import { useLanguage } from '@hooks/useLanguage'
import { DealerContacts } from '@layout/DealerContacts'
import { FlexContainer } from '@layout/FlexContainer'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { Page, PageContainerWithHead, PageContent, PageHead } from '@layout/Page'
import { PageSection } from '@layout/PageSection'
import { DiscountVehicles } from '@layout/DiscountVehicles'
import { CheckedText } from '@layout/CheckedText'
import { ContactForm } from '@forms/ContactForm'
import { urlWithQueryParamVehicleId } from '@pages/utils'
import { useRetrieveSelectionModeRelatedProps } from '@pages/useRetrieveSelectionModeRelatedProps'
import { ModalType } from './types'
import { buildSliderImages } from './utils'
import { VehicleFeatures } from './VehicleFeatures'
import { VehicleBasicInfo } from './VehicleBasicInfo'
import { VehicleInfoLabels } from './VehicleInfoLabels'
import { ModalBooking } from './Modals/ModalBooking'
import styles from './VehiclePage.module.css'
import { useVehiclePageBreadcrumbs } from './useVehiclePageBreadcrumbs'
import vehicleDefaultImage from '@assets/images/vehicleDefaultImage.png'
import { UrlString, VehicleId } from '@api/endpoints'
import { UserLanguageEnum } from '@model/language'
import { VehicleSelectionMode } from '@pages/types'
import { publicRoutes } from '@constants/routes'


export const VehiclePage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const { vehicleId, returnUrl } = useRetrieveSelectionModeRelatedProps()
  const getVehicle = useQueryGetVehicleById(vehicleId)
  const vehicle = getVehicle.data

  const isLoading = getVehicle.isFetching
  const { language } = useLanguage()

  const vehiclePageUrl = (
    vehicleId: VehicleId,
    language: UserLanguageEnum,
    selectionMode?: VehicleSelectionMode
  ): UrlString => {
    const baseUrl = generatePath(publicRoutes(language).VehiclePage, { vehicleId: vehicleId.toString() })
    return selectionMode ? `${baseUrl}?selectionMode=${selectionMode}` : baseUrl
  }

  const breadCrumbs = useVehiclePageBreadcrumbs(vehicle)

  const [modalOpen, setModalOpen] = React.useState<ModalType>()
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const handleModalClose = (): void => setModalOpen(undefined)
  const handleModalFormSuccess = (): void => setModalOpen(ModalType.Success)
  const handleOpenBookingModal = (): void => setModalOpen(ModalType.Booking)
  const handleOpenImageModal = (): void => setModalOpen(ModalType.Image)
  const handleOpenImage360Modal = (): void => setModalOpen(ModalType.Image360)
  const handleOpenVideoModal = (): void => setModalOpen(ModalType.Video)

  const youtubeLink = vehicle?.videoUrl ? `${vehicle.videoUrl.replace('/watch?v=', '/embed/')}?controls=1` : undefined

  const images = buildSliderImages(vehicle, handleOpenImageModal, vehicle?.isAvailable === false ? 3 : undefined)

  const mainImage = images.length > 0 ? images[0].srcSm : vehicleDefaultImage


  React.useEffect(() => {
    if (getVehicle.data) {
      document.getElementById(pageId)?.scrollTo(0, 0)
    }
  }, [getVehicle.data])

  return (
    <Page id={pageId} className={styles.vehiclePage}>
      {vehicle ? (
        <>
          <PageContent className={styles.vehiclePageHeadContainer}>
            <PageSection className={styles.vehiclePageHead}>
              <PageHead
                title={`${vehicle.mainData.year} ${vehicle.make} ${vehicle.model}`}
                breadCrumbs={breadCrumbs}
              />
            </PageSection>
          </PageContent>
          <PageContent className={styles.vehiclePageContainer}>
            <PageSection>
              <FlexContainer responsiveGap justifyContentSpaceBetween className={styles.upperRow}>
                {/* Microdata for the Vehicle */}
                <div
                  className={styles.vehicleBasicInfoSection}
                  itemScope
                  itemType="https://schema.org/Product"
                >
                  <VehicleBasicInfo vehicle={vehicle} />

                  <meta itemProp="name" content={`${vehicle.mainData.year} ${vehicle.make} ${vehicle.model}`} />
                  <meta itemProp="image" content={mainImage} />
                  <meta property="og:image" content={mainImage} />

                  <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
                    <meta itemProp="priceCurrency" content="EUR" />
                    <meta itemProp="price" content={vehicle.fullPrice?.toString() || 'N/A'} />
                    <link itemProp="availability"
                          href={vehicle.isAvailable ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'} />
                    <meta itemProp="url" content={vehiclePageUrl(vehicle.id, language)} />
                  </div>
                </div>

                {/* Media Slider */}
                <div className={clsx(styles.mediaSliderBox, 'mediaSlider-vehiclePage')}>
                  <FlexContainer fixedDirection justifyContentCenter className={styles.mediaSliderContainer}>
                    {!vehicle.isAvailable && (
                      <div className={styles.soldVehicleBadgeContainer}>
                        <span className={styles.soldVehicleBadge}>{t('pages.vehicle.sold')}</span>
                      </div>
                    )}
                    <div className={styles.mediaSliderButtonsContainer}>
                      {vehicle.image360Url && <CircleLink360 onClick={handleOpenImage360Modal} />}
                      {youtubeLink && <CircleLinkYoutube onClick={handleOpenVideoModal} />}
                    </div>
                    <MediaSlider
                      images={images}
                      slidesToShow={3}
                      currentSlide={currentSlide}
                      setCurrentSlide={setCurrentSlide}
                      disabled={isLoading}
                      hideArrows={vehicle.isAvailable === false}
                    />
                  </FlexContainer>
                </div>
              </FlexContainer>
              {vehicle.carWarranty && vehicle.carWarranty !== 'NONE' && (
                <div className={styles.vehiclePageWarranty}>
                  <LinkWarranty warrantyType={vehicle.carWarranty} />
                </div>
              )}
              <div className={styles.vehiclePageActions}>
                <div className={styles.vehiclePageActionsLeft}>
                  {returnUrl ? (
                    <Button
                      variant="contained"
                      className={styles.buttonChoose}
                      disabled={isLoading || !vehicle.isAvailable}
                      onClick={() => navigate(urlWithQueryParamVehicleId(returnUrl, vehicle.id))}
                    >
                      {t('pages.vehicle.buttons.choose')}
                    </Button>
                  ) : (
                    <ActionButtonsContainer fixedDirection noTopGap className={styles.primaryVehicleActionsContainer}>
                      <Button
                        fullWidth
                        variant="contained"
                        disabled={isLoading || !vehicle.isAvailable}
                        onClick={handleOpenBookingModal}
                      >
                        {t('pages.vehicle.buttons.book')}
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="white"
                        disabled={isLoading || !vehicle.isAvailable}
                        onClick={() => navigate(urlWithQueryParamVehicleId(routes.Exchange, vehicle.id))}
                      >
                        {t('pages.vehicle.buttons.exchange')}
                      </Button>
                    </ActionButtonsContainer>
                  )}
                </div>
                <VehicleInfoLabels vehicle={vehicle} />
              </div>
            </PageSection>

            <PageSection>
              <FlexContainer responsiveGap justifyContentSpaceBetween className={styles.lowerRow}>
                <VehicleFeatures vehicleId={vehicle.id} />
                <VehicleActualInfo className={styles.actualInfo} title={t('pages.vehicle.services.subtitle')}>
                  <VehicleLinksToActualInfo
                    vehicleId={vehicle.isAvailable ? vehicle.id : undefined}
                    vin={vehicle.mainData.vin}
                  />
                </VehicleActualInfo>
              </FlexContainer>
            </PageSection>
            <PageSection>
              <FlexContainer justifyContentSpaceBetween>
                <CheckedText>{t('pages.vehicle.marketing.finalPrice')}</CheckedText>
                <CheckedText>{t('pages.vehicle.marketing.certified')}</CheckedText>
                <CheckedText>{t('pages.vehicle.marketing.guarantee')}</CheckedText>
              </FlexContainer>
            </PageSection>
            <PageSection className={styles.section}>
              <ContactForm topic="other" />
              <DealerContacts />
            </PageSection>
            <PageSection maxWidth={false} disableRightGap disableLeftGap>
              <h3 className={clsx(styles.subtitle, styles.hPageGaps)}>{t('pages.vehicle.options.title')}</h3>
              <DiscountVehicles microdata={false}/>
            </PageSection>
          </PageContent>

          {/* Modals */}
          <ModalMediaSlider
            images={images}
            open={modalOpen === ModalType.Image}
            onClose={handleModalClose}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            disabled={isLoading}
          />
          <ModalPanorama
            url={vehicle.image360Url ?? ''}
            open={modalOpen === ModalType.Image360}
            onClose={handleModalClose}
          />
          <ModalPlayer
            src={youtubeLink ?? ''}
            width="100%"
            height="100%"
            allowFullScreen
            open={modalOpen === ModalType.Video}
            onClose={handleModalClose}
          />
          <ModalBooking
            vehicleId={vehicle.id}
            open={modalOpen === ModalType.Booking}
            onClose={handleModalClose}
            onSubmitSuccess={handleModalFormSuccess}
          />
          <ModalSuccess
            open={modalOpen === ModalType.Success}
            onClose={handleModalClose}
            title={t('pages.vehicle.notification.module_success_title')}
          />
        </>
      ) : (
        <PageContainerWithHead title={t('pages.vehicle.title')} breadCrumbs={breadCrumbs}>
          <PageContent>
            {isLoading && <CircularProgress />}
            <ContactForm topic="other" />
            <DealerContacts />
            <FlexContainer vertical>
              <h3 className={styles.subtitle}>{t('pages.vehicle.options.title')}</h3>
              <DiscountVehicles microdata={false}/>
            </FlexContainer>
          </PageContent>
        </PageContainerWithHead>
      )}
    </Page>
  )
}
